export const useCacheTimings = () => {
  const second = 1000;
  const minute = second * 60;
  const hour = minute * 60;

  return {
    homepage: {
      hot_titles: 1 * hour,
      most_followed_titles: 1 * hour,
      liked_titles: 1 * hour,
      fav_titles: 1 * hour,
      atf_carousel: 10 * minute,
      staff_picks: 10 * minute,
      new_titles: 1 * minute,
      latest_updates: 1 * minute,
    },
    library: {
      latest_updates: 1 * minute,
    },
    collection: {
      chapter: 1 * minute,
      title: 5 * minute,
      organization: 1 * minute,
      user: 1 * minute,
    },
    entity: {
      chapter: 5 * minute,
      title: 5 * minute,
      composite_stats: 5 * minute,
      title_rating: 1 * hour,
      chapter_languages: 1 * minute,
      grouped_chapter_list: 1 * minute,
      entity_access_map: 5 * minute,
      organization: 5 * minute,
      user: 20 * minute,
    },
    cdn_urls: {
      chapter_pages: 5 * minute,
    },
  };
};
